<template>
        <vx-card>
            <div class="container">
                <vs-row>
                    <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
                        <seanceGraphe ref="goSeance"></seanceGraphe>
                    </vs-col>

                    <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
                        <accessoireG ref="goAcc" ></accessoireG>
                    </vs-col>
                </vs-row>
            </div>
        </vx-card>
</template>

<script>
import apexchart from 'vue-apexcharts'
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate'
import cDate from '@/components/app/date'

import Terminal from '@/database/models/terminal'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Member from '@/database/models/member'
import seanceGraphe from '@/components/stats/components/graphFactComp/seanceG.vue'
import accessoireG from '@/components/stats/components/graphFactComp/accessoireG.vue'
import VxCard from '@/components/vx-card/VxCard.vue'

import autoRefresh from '@/assets/utils/autoRefresh'

export default {
    
    name:'facturationGraph',
    components:{
        apexchart,
        Item,
        Invoice,
        cDate,
        Member,
        Terminal,
        seanceGraphe,
        accessoireG,
        VxCard,
        autoRefresh
    },
    props:[
        'listfact',
    ],
    data(){
        return {
            options: {
                chart: {
                    id: 'vuechart-example'
                },
               
                xaxis:{
                    categories:['Jan','Fev','Mar','Avr','Mai','Juin','Jui','Aou','Sep','Oct','Nov','Dec']
                }
            },
            series: [{
                name: 'quantité',
                
                data:[],
            }],
            list_facture:[],
            vente_seance:[],
            vente_accesoire:[],
            total:0,
            dmin:1,
            dmax:(new Date()).getMonth()+1,
            listMoisItem:{},
        }
    },
    watch:{
        listMoisItem:function(){
           // console.log(" ------ - -- - -  listMoisItem changed ", this.listMoisItem)
        },
        vente_seance:function(){
            //console.log(" - -- - - - - - -  seance changed : ", this.vente_seance)
            this.$refs.goSeance.updateChart(this.vente_seance);
        },
        vente_accesoire:function(){
            //console.log(" - -- - - - - - -  acc changed : ", this.vente_accesoire)
            this.$refs.goAcc.updateChart(this.vente_accesoire);
        }
    },
    mounted:function()
    {
        autoRefresh.SetCallback(()=>{
            let year = (new Date()).getFullYear();
            this.frefresh( year )
        });
        autoRefresh.refresh();
    },
    methods:{
        getData(list){

            // on revoit la liste des factures 
            let listName=[];
            let listQte=[];
            
            for (let i =0; i<list.length; i++){
                

                // on verifie qu'il ne s'agit pas d'une facture, car sinon item = null 
                if (list[i].type !="Reçu"){
                    
                    // on recupere les données de l'item grâce à l'id de la facture 
                    Item.getTabAllMemoryKey( list[i].uid, function(items)
                    {
                        // pour l'instant on distingue pas accesoire et seance 
                        for(let j=0; j<items.length; j++){
                            // fitre séance 
                            if (items[j].credit!=0){
                               
                                if (listName.includes(items[j].name)){

                                   
                                    let x = listName.indexOf(items[j].name)
                                    // let x = -1;
                                    // for (let j=0; j < listName.length; j++){
                                    //     if (listName[j]==items[0].name){
                                    //         x=j;
                                    //     }
                                    // }
                                    listQte[x]=listQte[x]+1;

                                }
                                else{
                                    
                                    listName.push(items[j].name);
                                    listQte.push(1);
                                }
                            }
                        }
                        
                    })
                    
                }
            }
            
            let grandeList=[];
            // on met tout dan le data qu'utilisera apex 
            for (let i=0; i<listName.length; i++){
                let petiteList ={
                    x : listName[i],
                    y : listQte[i],
                }
                grandeList.push(petiteList);
            }
            
            this.series.data = listQte;
            this.options.xaxis.categories= listName;
            
            this.updateChart(listQte);
            
            
        },
        updateChart(list ) {
        
        // In the same way, update the series option
        this.series = [{
          data: list
        }]
      },
      updateOption(list){
          
          this.options={
              xaxis:{
                  categories:list
              }
          }
      },
      

    frefresh( param_year )
    {
        let today = new Date( param_year+'/12/31 23:59:59');
       
       
        const li=[];
        const seance=[];
        const acc=[];
        
        for(let j=0; j<12; j++){
            seance[j]=0;
            acc[j]=0;
        }
        
        Invoice.getAllMemory(function(invoice)
        {

            //console.log(" facture : ", invoice)
            
            let list = []
            // o,n recupere les id 
            for (let inv in invoice){
                list.push(inv);
            }
           
            let y = today.getFullYear();
            
            let factY =[];
            // on recupere les invoices de cette année 
            for(let i=0; i<list.length; i++){
                if (invoice[list[i]].createAt.getFullYear()==y){
                    factY.push(invoice[list[i]]);
                }
            }
           
            
            // mtn il faut recuperer les factures de chaques mois 

            let monthFact = [];
            for (let i=0; i<12; i++){
                monthFact.push([]);
            }
            
            // je recuper les factures en fonction des mois -- ok 
            for (let m =1; m<=today.getMonth()+1; m++){
                let mList=[];
                
                for (let i =0; i<factY.length; i++){
                  
                   if ((factY[i].createAt.getMonth()+1)===m){
                        
                        mList.push(factY[i]);
                    }
                }
                
                monthFact[m-1]=mList;
            }
        
           
            // ----   -------------
            function completeDate(buffer, listF)
            {
                
                Member.getMemory( buffer.member, function(mbr)
                {
                    //Si pas de membre ?
                    let p_member = { first_name:'Aucun', last_name:'' };
                    if(mbr)
                        p_member = mbr;
                    
                    //get vendor
                    Member.getMemory(  buffer.vendor, function(ved)
                    {
                        let p_vendor = { first_name:'Aucun', last_name:'' };
                        if(ved)
                            p_vendor = ved;

                        //payement
                        let paye = []
                        if(buffer.pay_money!=0)
                            paye.push({name:'ESP', value:buffer.pay_money})

                        if(buffer.pay_cb!=0)
                            paye.push({name:'CB',value:buffer.pay_cb})

                        if(buffer.pay_check!=0)
                            paye.push({name:'CHEQ',value:buffer.pay_check})

                        if(buffer.pay_dif!=0)
                            paye.push({name:'DIFF',value:buffer.pay_dif})

                        if(buffer.pay_prele!=0)
                            paye.push({name:'PREL',value:buffer.pay_prele})

                        //Item

                        Item.getTabAllMemoryKey( buffer.uid, function(items)
                        {
                        
                            //add
                            listF.push({
                                uid      : buffer.uid,
                                type     : Invoice.getTypeName(buffer),
                                date     : Fdate.getFullDateMin(buffer.createAt,true)+" "+Fdate.getHeure(buffer.createAt),
                                vdate    : Fdate.getFullDateMin(buffer.createAt),
                                number   : buffer.number,
                                vendor   : {'first_name':Utils.StrCapital(p_vendor.first_name), 'last_name':Utils.StrCapital(p_vendor.last_name)},
                                member   : {'first_name':Utils.StrCapital(p_member.first_name), 'last_name':Utils.StrCapital(p_member.last_name)},
                                item     : items,
                                payement : paye,
                                total    : (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele).toFixed(2),
                            });
                        });
                    });
                });
            }
           
            function calcul(list, s,a , id)
            {
               
                for (let it=0; it<list.length; it++)
                {
                    // if(ite.prix!=0)
                    // if(ite.tva!=0)
                    if(list[it].type!='Reçu')
                    {
                         
                        for (let j=0; j<list[it].item.length; j++)
                        {
                            let ite = list[it].item[j];
                            
                            let total = 0
                            //Si Facture
                            if( ite.type =='fa' || ite.type =='re' )
                                total = parseFloat((ite.prix * ((ite.tva/100)+1)))
                                
                            //Sinon avoir
                            if( ite.type =='av' )
                                total = parseFloat((ite.prix * (( (ite.tva*-1)/100)+1)))

                            if( ite.credit!=0){
                                
                                s += total
                            }
                            else
                                a += total 
                        }
                        
                    }
                    if( it==list.length-1)
                    {
                        seance[id] = s.toFixed(2);
                        acc[id]    = a.toFixed(2);  
                    }
                }
            }
           
           for (let v =0; v<monthFact.length; v++){
               let listF=[];
               let se =0;
               let ac=0;
               for(let j=0; j<monthFact[v].length; j++){
                   completeDate(monthFact[v][j],listF)
               }
               li.push(listF)
               calcul(li[v], se, ac, v)
            

           }
        });

        
        
      
        
       // on recupere les infos pour chaque mois 
        this.listMoisItem=li;
        this.vente_seance=seance;
        this.vente_accesoire=acc;


        this.$refs.goSeance.updateChart(this.vente_seance);
        this.$refs.goAcc.updateChart(this.vente_accesoire);
    },
   
    
}
    
}
</script>


<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
.espace{
    padding-left: 5px;

}
</style>